import { animate,  keyframes,  style, transition, trigger } from '@angular/animations';

export const spinnerAnimation = trigger('spinner', [



  transition('* => *',  [
    style({
      transform: 'translate(-50%,-50%)'

    }),
    animate(`1.2s  cubic-bezier`, keyframes ( [
      style({ opacity: 0.1, offset: 0.1 }),
      style({ opacity: 0.6, offset: 0.2 }),
      style({ opacity: 1,   offset: 0.5 }),
      style({ opacity: 0.2, offset: 0.7 })
    ]))
  ])
]);
