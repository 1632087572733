
import { ClincDto } from './../../../interfaces/ClincDTO';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, catchError, map, Observable, Subject, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxPermissionsService } from 'ngx-permissions';
import { GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { GeneralService } from '../general.service';
import { GenericCrudService } from '../generic-crud-service.service';
import { HelperService } from '../helper.service';
@Injectable()
export class AuthService {
  token: string;
  baseURL = '';
  url = '';
  user :ClincDto
   httpOptions = {
    headers: new HttpHeaders({'Content-Type':'application/json; charset=utf-8'})
  }
  private isLogged$ = new BehaviorSubject(false);
  private authChangeSub = new Subject<boolean>();


  constructor(private genericService:GenericCrudService<any>,private httpClient: HttpClient,private jwtHelper: JwtHelperService,public permissionsService: NgxPermissionsService,
    private helperService:HelperService,
    public activatedRoute:ActivatedRoute,
    private router: Router,) {

    this.baseURL = environment.apiUrl +'Authentication/';

    this.token=sessionStorage.getItem('jwt')
  }
  public get isLoggedIn(): boolean {
    return this.isLogged$.value;
  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }


  signinUser11(item: any) {

    const formData = new FormData();
    Object.keys(item).forEach(key => { formData.set(key, item[key]); });
    return this.httpClient.post<any>(this.baseURL + 'Login', formData).subscribe(
      (data) => this.token=data.id,
      (error) => console.log(error)
    );

  }
  public signinUser(item: any) {
    const permissionsArray:string[]=[]
    const formData = new FormData();
    Object.keys(item).forEach(key => { formData.set(key, item[key]); });
    const body=JSON.stringify(item);
    const headers = { 'content-type': 'application/json'}

    return this.httpClient.post<any>(this.baseURL + 'Login/', body,{'headers':headers})
    .pipe(
      map((res: { token: string, id: string, permissions: string, setupTable: string, email: string, mobileNo: string }) => {
        if (res.token !== undefined) {
          this.setToken(res.token);
          sessionStorage.setItem('userId', res.id);
          var user = {
            'email': res.email,
            'mobileNo': res.mobileNo,
            'id': res.id
          };
          sessionStorage.setItem('userDetails', JSON.stringify(user));
        } else {
          this.helperService.openSnackBar("اسم المستخدم أو كلمة المرور غير صحيحة");
          return;
        }
        if (res.setupTable !== undefined) {
          sessionStorage.setItem("SetupTable", res.setupTable);
        }
        var permissions = JSON.parse(res.permissions)[0];
        Object.keys(permissions).forEach(key => {
          if (permissions[key] == true) {
            permissionsArray.push(key.toString());
          }
        });
        this.permissionsService.loadPermissions(permissionsArray);
        if (this.isAuthenticated() == true) {
          this.router.navigate(['/dashboard'], { relativeTo: this.activatedRoute });
        }
        //  this.generalService.gtSetupTable();
        return item;
      }),
      catchError(error => {
        // Handle error here, log it or perform other actions
        debugger
        console.error('Error occurred:', error);
        this.helperService.openSnackBar(error.error)
        // You can also throw a new error or return a specific value
        return throwError('An error occurred while logging in. Please try again later.');
      })
    );
  }

  logout() {
    this.token = null;
    sessionStorage.removeItem('jwt');
  }

  getToken() {

    return sessionStorage.getItem('jwt')
  }
  setToken(token:string) {
    sessionStorage.setItem('jwt',token);
  }

  isAuthenticated() {
    if(this.getToken() !=undefined && !this.jwtHelper.isTokenExpired(this.getToken() ) )
    return true
    return false;
  }

  getUserByAccountId() {
   var AccountId= sessionStorage.getItem("AccountId")
    let params = new HttpParams().set('AccountId', AccountId)

    return new GenericCrudService<any>(this.httpClient)
    .setSegmantName('Authentication')
     .getListWithParam('getUserByAccountId',params)
  }

  LoginByGoogle() {
    const permissionsArray:string[]=[]
   var AccountId= sessionStorage.getItem("AccountId")
    let params = new HttpParams()
    .set('AccountId', AccountId)
    return new GenericCrudService<any>(this.httpClient)
    .setSegmantName('Authentication')
     .getListWithParam('LoginByGoogle',params)

  }
}
