import { PaymentComponent } from './../../../app/core/components/payment/payment.component';

import { PaymentService } from './../../../app/core/services/payment.service';
import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PaymentMethod } from 'src/app/core/interfaces/PaymentMethod';

import { ActivationCodesComponent } from 'src/app/core/components/activation-codes/activation-codes.component';

//import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { AuthService } from 'src/app/core/services/shared/auth/auth.service';

@Component({
  selector: 'tamam-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @ViewChild('googleSignInButton') googleSignInButton: ElementRef;

IsVisible=true;
  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;
  items: PaymentMethod[];

  navigationItems = this.navigationService.items;
AccountUserId=0
  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));
  userVisible$: Observable<boolean> = this.configService.config$.pipe(map(config => config.toolbar.user.visible));

  megaMenuOpen$: Observable<boolean> = of(false);

  hospital: any;
  router: any;
  activatedRoute: any;
  IsSubscriptionEnable=false;
  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private popoverService: PopoverService,

            //  private socialAuthService: SocialAuthService,
              private authservice:AuthService,
              private dialog: MatDialog,
          ) { }

  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }
   OpenActivationCode(){
   /* var AccountId = sessionStorage.getItem("AccountId")
    if(AccountId==null|| AccountId==undefined)
    {

      const buttonElement = this.googleSignInButton.nativeElement;
      const iframe=buttonElement.querySelector('iframe')
      const iframeElement = iframe as HTMLIFrameElement;

      const iframeContent = iframeElement.contentWindow.document;
     // const googleSignInButton = iframeContent.querySelector('#button-label');


    }
    else{

    }*/
    this.dialog.open(ActivationCodesComponent,{
      height: '400px',

    })
  }
  ngOnInit() {
   /* this.socialAuthService.authState.subscribe((user:SocialUser) => {
      sessionStorage.setItem("AccountId",user.id)

      this.authservice.LoginByGoogle().subscribe(
        res => {

          const permissionsArray:string[]=[]
          if(res["account"]!=undefined)
          {

            var googleAccount=res["account"]
            sessionStorage.setItem('AccountUserId',googleAccount["id"] );
            this.AccountUserId=googleAccount["id"]

          }
          if( res["permissions"] !=undefined)
          {

          this.IsSubscriptionEnable=res["permissions"].smsAdmin

          }
        }
       )


    })*/

  }
  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }
  openPaymentDialog(){
   /* console.log("1")
    var AccountId = sessionStorage.getItem("AccountId")
    if(AccountId==null|| AccountId==undefined)
    {
      debugger
      const buttonElement = this.googleSignInButton.nativeElement;
      const iframe=buttonElement.querySelector('iframe')
      const iframeElement = iframe as HTMLIFrameElement;

      const iframeContent = iframeElement.contentWindow.document;
      const googleSignInButton = iframeContent.querySelector('#button-label');


    }
    else{

    }*/

    this.dialog.open(PaymentComponent,{
      height: '500px',
      width: '600px',
    })
  }
  openSearch(): void {
    this.layoutService.openSearch();
  }


}
