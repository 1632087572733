<div [class.container]="isVerticalLayout$ | async"  class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block flex items-center">
    <img alt="Logo" class="w-8 select-none" src="assets/img/demo/logo.svg"/>
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">VEX</h1>
  </a>

  <button *ngIf="isHorizontalLayout$"
          [class.hidden]="mobileQuery"
          color="primary"
          mat-flat-button
          (click)="openPaymentDialog()"
          type="button">انشاء كود التفعيل
  </button>
  <!--<button *ngIf="isHorizontalLayout$"
  [class.hidden]="mobileQuery"
  color="info"
  mat-flat-button
  (click)="OpenActivationCode()"
  type="button">عرض كود التفعيل
</button>-->
<span>

</span>



  <!--< <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [class.hidden]="mobileQuery"
       class="px-gutter flex-none flex items-center">
    <tamam-navigation-item *ngFor="let item of navigationItems" [item]="item"></tamam-navigation-item>
  </div>-->

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center" >
    <!--<div class="px-1" *ngIf="AccountUserId!=0">
      رقم المستخدم:
      {{AccountUserId}}
    </div>
    <div class="px-1">
      <button (click)="openSearch()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:search"></mat-icon>
      </button>
    </div>

    <div class="px-1">
      <tamam-toolbar-notifications></tamam-toolbar-notifications>
    </div>

    <div class="px-1">
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:bookmarks"></mat-icon>
      </button>
    </div>
-->
    <div class="px-1">
      <button [matMenuTriggerFor]="smsMenu" mat-icon-button type="button" style="border:solid 1px black;" *ngIf="IsSubscriptionEnable==true">
        {{AccountUserId}}
      </button>
    </div>
    <div class="px-1" *ngIf="IsSubscriptionEnable==false"  >
    <!---->
<!--<asl-google-signin-button type='standard'  #googleSignInButton size='medium' ></asl-google-signin-button>-->
    </div>

    <div *ngIf="userVisible$ | async" class="px-1">
      <tamam-toolbar-user></tamam-toolbar-user>
    </div>

    <mat-menu #smsMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <a  routerLink="sms-subscription" mat-menu-item>
        <mat-icon svgIcon="mat:subtitles"></mat-icon>
        <span>اشتراكات الرسائل</span>
      </a>

      <a  routerLink="sms-Template" mat-menu-item>
        <mat-icon svgIcon="mat:list_alt"></mat-icon>
        <span>النماذج</span>
      </a>
    </mat-menu>
  </div>
</div>

<tamam-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [class.hidden]="mobileQuery"></tamam-navigation>
