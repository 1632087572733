<ng-template #sidenavRef>
  <tamam-sidenav [collapsed]="sidenavCollapsed$ | async"></tamam-sidenav>
</ng-template>



<ng-template #footerRef>
  <tamam-footer *ngIf="isFooterVisible$ | async" class="tamam-footer"></tamam-footer>
</ng-template>

<ng-template #quickpanelRef>
  <tamam-quickpanel></tamam-quickpanel>
</ng-template>

<tamam-layout [footerRef]="footerRef"
            [quickpanelRef]="quickpanelRef"
            [sidenavRef]="sidenavRef"
            ></tamam-layout>

<tamam-config-panel-toggle (openConfig)="configpanel.open()"></tamam-config-panel-toggle>

<!-- CONFIGPANEL -->
<tamam-sidebar #configpanel [invisibleBackdrop]="true" position="right">
  <tamam-config-panel></tamam-config-panel>
</tamam-sidebar>
<!-- END CONFIGPANEL -->
