
import { JwtHelperService } from '@auth0/angular-jwt';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { JwtModule } from "@auth0/angular-jwt";
import { NetworkInterceptor } from 'src/@tamam/layout/spinner/network.interceptor';
import { AesEncryptDecryptService } from './core/services/shared/aes-encrypt-decrypt.service';
import { AuthGuard } from './core/services/shared/auth/auth-guard.service';
import { AuthService } from './core/services/shared/auth/auth.service';

//import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';

import { NgxPermissionsModule } from 'ngx-permissions';
import { HomeModule } from './core/components/home/home.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';

export function tokenGetter() {
  return localStorage.getItem("jwt");
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxMatNativeDateModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:7299", "https://analysisresult.tamam.ly"],
        disallowedRoutes: []
      }
    }),
    // Tamam
    MatSnackBarModule,
    CustomLayoutModule,

  ],
  providers: [AuthService,

    AuthGuard,
    AesEncryptDecryptService,
    JwtHelperService,

    { provide: HTTP_INTERCEPTORS, useClass: NetworkInterceptor, multi: true },
    /*{
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '595990475245-1mqg0lovfud0ipenid0kl88a85l0p22b.apps.googleusercontent.com'
              // '688391750834-2pdseatq0mtpbsurksiotqegnqliuubp.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('3447373265495043')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }*/

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
