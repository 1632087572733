import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  currentyear=(new Date()).getFullYear()
  constructor() { }
  ngOnInit(): void {
  }
  //this is a variable that hold number
  experincecount:number = 0;
  //same process
  accuratecount:number = 0;
  clientcount:number = 0;
  citycount:number = 0;

  //we have created setinterval function with arrow function and
  //and set them in a variable that is experincecountstop.
  experincecountstop:any = setInterval(()=>{
    this.experincecount++;
    //we need to stop this at  particular point; will use if condition
    if(this.experincecount ==20)
    {
      //clearinterval will stop tha function
      clearInterval(this.experincecountstop);
    }

  },40) //10 is milisecond you can control it


  accuratecountstop:any = setInterval(()=>{
    this.accuratecount++;
    if(this.accuratecount == 95)
    {

      clearInterval(this.accuratecountstop);
    }
  },40)


  clientcountstop:any = setInterval(()=>{
    this.clientcount++;
    if(this.clientcount == 100)
    {

      clearInterval(this.clientcountstop);
    }
  },40)

  citycountstop:any = setInterval(()=>{
    this.citycount++;
    if(this.citycount == 15)
    {

      clearInterval(this.citycountstop);
    }
  },40)

  //conclusion: we have use
  //string interpolation
  //setInterval function
  //()=> arrow function
  //clearInterval
  //creating variable


}
