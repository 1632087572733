import { PaymentDTO } from './../interfaces/Payment';
import { ClincDto } from 'src/app/core/interfaces/ClincDTO';
import { GenericCrudService } from 'src/app/core/services/shared/generic-crud-service.service';
import { Injectable } from '@angular/core';
import { AesEncryptDecryptService } from './shared/aes-encrypt-decrypt.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { PaymentMethod } from '../interfaces/PaymentMethod';
import { ActivationCodesDTO, CodesDTO } from '../interfaces/ActivationCodesDTO';
import { HelperService } from './shared/helper.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private paymentMethods :PaymentMethod
  private PaymentSource = new BehaviorSubject(null); // set default status
  currentPayment = this.PaymentSource.asObservable();
  clinic: ClincDto ;
  constructor( public aesEncryptDecryptService:AesEncryptDecryptService ,
    private genericService:GenericCrudService<ClincDto>,
    private httpClient: HttpClient ,
    private helperService:HelperService) { }
  pay(GateWayCommossion:String,Year:String,Month:String,Email:String,Phone:String){
  const clinic = JSON.parse(this.aesEncryptDecryptService.decrypt(sessionStorage.getItem("clinic")))
  clinic.Commission=GateWayCommossion.toString();
  clinic.Year=Year.toString();
  clinic.Month=Month.toString();
  clinic.Email=Email
  clinic.Phone=Phone

  this.genericService.setControllerAction("Payment","Pay");
 return new GenericCrudService<ClincDto>(this.httpClient)
  .setControllerAction("Payment","Pay")
  .Post(clinic)

  }
  getPaymentMethods(){

    this.genericService.setControllerAction("Payment","Pay");
   return new GenericCrudService<ClincDto>(this.httpClient)
    .setControllerAction("Payment","Pay")
    .getList('')

    }

    getActivationCodes(){

       this.clinic = this.helperService.GetCurrentClinic()

      let params = new HttpParams().set('ClinicNo', this.clinic.ID.toString());
      this.genericService.setControllerAction("Payment","ActivationCodes");
      return new GenericCrudService<CodesDTO>(this.httpClient)
      .setSegmantName('Payment')
       .getListWithParam('ActivationCodes',params)

    }

    getPaymentWay(){



      let params = new HttpParams().set('TableName', 'PaymentGateWay').set("UserID",sessionStorage.getItem("AccountId"));
     this.genericService.setControllerAction("Payment","PaymentGateWay");
     return new GenericCrudService<PaymentMethod>(this.httpClient)
     .setSegmantName('Payment')
      .getListWithParam('PaymentGateWay',params)

   }
    AssignUrl(url: string) {
    this.PaymentSource.next(url)
  }
  GetWalletValue(){
    var AccountId = sessionStorage.getItem("AccountId")


   let params = new HttpParams().set('UserId', AccountId);
   return new GenericCrudService<CodesDTO>(this.httpClient)
   .setSegmantName('Payment')
    .getListWithParam('GetWalletValue',params)

 }
}
