import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { parse } from 'path';
import Swal from 'sweetalert2';
import { AesEncryptDecryptService } from './aes-encrypt-decrypt.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(public aesEncryptDecryptService:AesEncryptDecryptService ,public datepipe: DatePipe,private _snackBar: MatSnackBar) { }

  FromatDate(date :string)
  {

    if(date==undefined)
    return '';
    return this.datepipe.transform(date, 'dd-MM-yyyy HH:mm')
  }

  GetTimeFromDate(date :string)
  {
    return this.datepipe.transform(date, 'HH:mm')
  }
  GetGender(IsMale :boolean)
  {
    return IsMale==true?"ذكر":"انثى"
  }
  openSnackBar(Message:string) {
    this._snackBar.open(Message, 'OK', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 3000
    });
  }
  GetAge(date :string)
  {
     var defaultdatTime = new Date()
     var BirthDate= new Date(date)
    if (date!=null)
    {
       var age = defaultdatTime.getTime() - BirthDate.getTime();
       var NumberDays=Math.floor(age/(1000*3600*24))
       var NumberYears=Math.floor( age/(1000*3600*24)/365)
        if (NumberYears == 0)
        {
          NumberDays = (NumberDays / 30);
            if (NumberDays == 1)
            {
                return NumberDays+ " month";
            }
            else
            {
                return NumberDays+ " months";
            }
        }

        else
        {
            if (NumberDays == 1)
            {
                return NumberYears + " year";
            }
            else
            {
                return NumberYears + " years";
            }
        }

    }

  }
   getDayName(DayName: string): string {
    if (DayName === 'Saturday') {
      return 'السبت';
    }
    if (DayName === 'Sunday') {
      return 'الاحد';
    }
    if (DayName === 'Monday') {
      return 'الاثنين';
    }
    if (DayName === 'Tuesday') {
      return 'الثلاثاء';
    }
    if (DayName === 'Wednesday') {
      return 'الاربعاء';
    }
    if (DayName === 'Thursday') {
      return 'الخميس';
    }
    return 'الجمعة';
  }
  GetNumberOfDays(year: number, month: number): number {
    // Create a new Date object with the given year and month (months are zero-based)
    const date = new Date(year, month, 1);

    // Move to the next month and subtract 1 day to get the last day of the given month
    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() - 1);

    // Return the day component of the date, which gives the number of days in the month
    return date.getDate();
  }

  OpenSweetAlertConfirmMessage(titleMessage,iconType,confirmLabel,cancelLabel,cancelMessage,callBack){
    Swal.fire({
      title: titleMessage,
      icon: iconType,
      showCancelButton: true,
  confirmButtonText: confirmLabel,
  cancelButtonText:cancelLabel
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
      callBack()

      } else if (result.isDenied) {
        if(cancelMessage!='')
        {
          Swal.fire(cancelMessage, '', 'info')
        }
      }
    })

  }

  GetCurrentClinic(){
     return   JSON.parse(this.aesEncryptDecryptService.decrypt(sessionStorage.getItem("clinic")))
  }
  public IsUpdate(){
   var clinic =JSON.parse(this.aesEncryptDecryptService.decrypt(sessionStorage.getItem("clinic")))
   if(clinic.IpAddress!=".")
   {
      this.openSnackBar("المنظومة قيد التطوير , لايمكن التعديل حاليا")
      return;
   }
  }
   convertObjectValuesToStrings(obj: { [key: string]: any }): { [key: string]: string } {
    const result: { [key: string]: string } = {};

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            result[key] = obj[key].toString();
        }
    }

    return result;
}

 ConfirmMessage(title,icon,confirmText,cancelText,success,Cancel){
  Swal.fire({
    title: title,
    icon: icon,
    showCancelButton: true,
confirmButtonText: confirmText,
cancelButtonText:cancelText
  }).then((result) => {

    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      success()

    }
    else if (result.isDismissed) {
      Cancel()
    }
  })
}

}
