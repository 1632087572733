
import { AuthGuard } from './core/services/shared/auth/auth-guard.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { TamamRoutes } from '../@tamam/interfaces/tamam-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { HomeComponent } from './core/components/home/home.component';

const routes: TamamRoutes = [
  {
    path: 'clinic',
    loadChildren: () => import('./core/components/clink/clinck.module').then(m => m.ClinicModule),
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },

  {
    path: 'analysis-report',
    loadChildren: () => import('./core/components/Analysis/Components/analysis-report/analysis-report.module').then(m => m.AnalysisReportModule),


  },
  {
    component: CustomLayoutComponent,
    path: 'dashboard',
    loadChildren: () => import('./custom-layout/custom-layout.module').then(m => m.CustomLayoutModule),

    canActivate: [AuthGuard],

  },
  {
    path: 'analysis-result',
    loadChildren: () => import('./core/components/Analysis/Components/analysis-result/analysis-result.module').then(m => m.AnalysisResultModule),




  },
  {
    path: '',
    component: HomeComponent


  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
