import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { PaymentComponent } from './payment.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatRippleModule } from '@angular/material/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { GenericCrudService } from '../../services/shared/generic-crud-service.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';

import { ActivationCodesComponent } from '../activation-codes/activation-codes.component';
import { MatTabsModule } from '@angular/material/tabs';



@NgModule({
  declarations: [PaymentComponent,ActivationCodesComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatRippleModule,
    MatOptionModule,
    MatDividerModule,
    MatDialogModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
  ],
  providers:[GenericCrudService,ActivationCodesComponent ,DatePipe],
  exports:[PaymentComponent]
})
export class PaymentModule { }
