<ng-container *ngIf="loading$ | async">
  <div class="overlay"></div>
  <div  class="spinner-back">
    <div class="spinner-div">
        <svg class="spinner-box " viewBox="0 0 100 100">
            <defs>
                <filter id="shadow">
                    <feDropShadow dx="0" dy="0" stdDeviation="1.5"
                                  flood-color="#1FDF98" />
                </filter>
            </defs>
            <circle  id="spinner" style="fill: transparent; stroke: #1FDF98; stroke-width: 7px; stroke-linecap: round; filter: url(#shadow);" cx="50" cy="50" r="45" />
        </svg>
    </div>
</div>
</ng-container>
<router-outlet></router-outlet>

