import { Component, OnInit } from '@angular/core';
import { PopoverRef } from '../popover/popover-ref';
import { MatDialog } from '@angular/material/dialog';
import { PaymentComponent } from 'src/app/core/components/payment/payment.component';

@Component({
  selector: 'tamam-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  constructor(private readonly popoverRef: PopoverRef, private dialog: MatDialog,) { }

  ngOnInit(): void {
  }

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
sessionStorage.clear()

  }
  openPaymentDialog(){
    /* console.log("1")
     var AccountId = sessionStorage.getItem("AccountId")
     if(AccountId==null|| AccountId==undefined)
     {
       debugger
       const buttonElement = this.googleSignInButton.nativeElement;
       const iframe=buttonElement.querySelector('iframe')
       const iframeElement = iframe as HTMLIFrameElement;

       const iframeContent = iframeElement.contentWindow.document;
       const googleSignInButton = iframeContent.querySelector('#button-label');


     }
     else{

     }*/

     this.dialog.open(PaymentComponent,{
       height: '500px',
       width: '600px',
     })
   }
}
