
<div class="flex items-center" mat-dialog-title>

  <h2 class="headline m-0 flex-auto">دفع</h2>




  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-divider class="-mx-6 text-border"></mat-divider>
<form (ngSubmit)="Pay()" [formGroup]="paymentFormGroup">
  <mat-dialog-content class="flex flex-col">

    <mat-form-field>
      <mat-label>السنة</mat-label>

      <mat-select  formControlName="year"   >
        <mat-option *ngFor="let year of years"   [value]="year">
          {{year}}
        </mat-option>
      </mat-select>
      <mat-icon class="ltr:mr-3 rtl:ml-3" matPrefix svgIcon="mat:date_range"></mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>الشهر</mat-label>

      <mat-select  formControlName="month"   >
        <mat-option *ngFor="let month of months"   [value]="month">
          {{month}}
        </mat-option>
      </mat-select>
      <mat-icon class="ltr:mr-3 rtl:ml-3" matPrefix svgIcon="mat:date_range"></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto " >
      <mat-label>البريد الاكتروني </mat-label>
      <input type="email" cdkFocusInitial formControlName="email" matInput>
      <mat-error *ngIf="getEmail().invalid&&getEmail().touched&&getEmail().dirty==true"> الرجاء ادخال اليريدالإلكتروني</mat-error>


    </mat-form-field>

    <mat-form-field class="flex-auto " >
      <mat-label>رقم الهاتف </mat-label>
      <input type="text" cdkFocusInitial formControlName="phone" matInput>

      <mat-error *ngIf="getEmail().invalid&&getEmail().touched&&getEmail().dirty==true"> الرجاء ادخال رقم الهاتف </mat-error>

    </mat-form-field>
  </mat-dialog-content>
</form>

<mat-dialog-actions align="end">
  <span class="total">
    {{Total}}
  </span>
  <button mat-button mat-dialog-close type="button">الغاء</button>
  <button color="primary" mat-flat-button type="submit" (click)="Pay()">
    <ng-container>استمرار</ng-container>

  </button>

</mat-dialog-actions>



