import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'tamam-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {


items=[]
  constructor(private navigationService: NavigationService) { }

  ngOnInit() {

    this.items = this.navigationService.items;
  }
}
