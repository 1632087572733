<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<table mat-table [dataSource]="Activation">



  <!-- Weight Column -->
  <ng-container matColumnDef="Code">
    <th mat-header-cell *matHeaderCellDef> رمز التفعيل </th>
    <td mat-cell *matCellDef="let element">
      {{  element.Code.slice(0, 5) + "-" + element.Code.slice(5,10)+ "-" +element.Code.slice(10)}}
      <mat-icon class="" style="cursor:pointer;" (click)="CopyCode(element.Code)">content_copy</mat-icon>
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="ReferenceStr">
    <th mat-header-cell *matHeaderCellDef > تاريخ التفعيل </th>
    <td mat-cell *matCellDef="let element"> {{element.ReferenceStr}} </td>
  </ng-container>
  <ng-container matColumnDef="CreationDate">
    <th mat-header-cell *matHeaderCellDef> تاريخ الاضافة. </th>
    <td mat-cell *matCellDef="let element"> {{FromatDate(element.CreationDate) }} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
