import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule } from 'ngx-quicklink';
import { CustomLayoutComponent } from './custom-layout.component';


const routes: Routes = [

  {
    path: 'sms-message-send',
    loadChildren: () => import('../core/components/sms/sms-subscription/sms-subscription.module').then(m => m.SmsSubscriptionModule),


  },
  {
    path: 'voucher-transfare',
    loadChildren: () => import('../core/components/Cash/Components/VoucherTransfare/voucher-transfare/voucher-transfare.module').then(m => m.VoucherTransfareModule),




  },
  {
    path: 'employee',
    loadChildren: () => import('../core/components/AdministrationAffairs/EmployeeManagement/employee-managment/employee-managment.module').then(m => m.EmployeeManagmentModule),




  },
  {
    path: 'users',
    loadChildren: () => import('../core/components/UserManagement/user/user.module').then(m => m.UserModule),




  },
  {
    path: 'voucher-in-clinic',
    loadChildren: () => import('../core/components/Cash/Components/Reciept/treasury/treasury.module').then(m => m.TreasuryModule),




  },
  {
    path: 'voucher-out',
    loadChildren: () => import('../core/components/Cash/Components/VoucherOut/voucher-out/voucher-out.module').then(m => m.VoucherOutModule),




  },
  {
    path: 'operation',
    loadChildren: () => import('../core/components/In-Patient/Components/Operation/operation/operation.module').then(m => m.OperationModule),




  },
  {
    path: 'time-sheet',
    loadChildren: () => import('../core/components/AdministrationAffairs/TimeSheet/time-sheet/time-sheet.module').then(m => m.TimeSheetModule),




  },
  {
    path: 'sms-subscription',
    loadChildren: () => import('../core/components/sms/sms-subscription/sms-subscription.module').then(m => m.SmsSubscriptionModule),




  },
  {
    path: 'analysis-result',
    loadChildren: () => import('../core/components/Analysis/Components/analysis-result/analysis-result.module').then(m => m.AnalysisResultModule),




  },
  {
    path: 'reception',
    loadChildren: () => import('../core/components/reception/reception.module').then(m => m.ReceptionModule),




  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomLayoutRoutingModule {
}
