import { ClincDto } from "./../../interfaces/ClincDTO";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { PaymentService } from "../../services/payment.service";
import { PaymentMethod } from "../../interfaces/PaymentMethod";
import { AesEncryptDecryptService } from "../../services/shared/aes-encrypt-decrypt.service";
import { HelperService } from "../../services/shared/helper.service";

@Component({
  selector: "tamam-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PaymentComponent,
      multi: true,
    },
  ],
})
export class PaymentComponent implements OnInit {
  paymentFormGroup: UntypedFormGroup;
  selectedYear: number;
  selectedMonth: number;
  items: PaymentMethod[];
  years: number[] = [];
  months: number[] = [];
  Total: String;
  GateWayCommision = 3.5;
  clinic: ClincDto;
  constructor(
    private dialogRef: MatDialogRef<PaymentComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: UntypedFormBuilder,
    private paymentService: PaymentService,
    private helperService: HelperService,
    private aesEncryptDecryptService: AesEncryptDecryptService,

  ) {}

  ngOnInit() {

    var user = JSON.parse(sessionStorage.getItem('userDetails')) ;

    this.paymentFormGroup = this.fb.group({
      year: this.selectedYear,
      month: this.months[this.selectedMonth],
      email:[user['email'], Validators.required],
      phone:[user['mobileNo'], Validators.required]
    });

    for (let year = 2000; year <= 2050; year++) {
      this.years.push(year);
    }
    for (let month = 1; month <= 12; month++) {
      this.months.push(month);
    }
    this.selectedYear = new Date().getFullYear();
    this.selectedMonth = new Date().getMonth();

    this.paymentFormGroup = this.fb.group({
      year: this.selectedYear,
      month: this.months[this.selectedMonth],
      email:user['email'],
      phone:user['mobileNo']
    });
    this.clinic = JSON.parse(
      this.aesEncryptDecryptService.decrypt(sessionStorage.getItem("clinic"))
    );
    let Price = parseInt(this.clinic.Value.toString());
    if(isNaN(Number(Price))  ){
      this.Total="لايوجد قيمة للمصحة"
       return;
    }
    else{
      let commosionprice =
      (Price * parseFloat(this.GateWayCommision.toString())) / 100;
    this.Total =
      "المبلغ المدفوع: " +
      this.clinic.Value.toString() +
      "+" +
      commosionprice +
      "=" +
      (Price + commosionprice);
    }

  }
getEmail(){

  return this.paymentFormGroup.get('email')
}
  Pay() {
    let Price = parseInt(this.clinic.Value.toString());
    if(isNaN(Number(Price))  ){
      this.helperService.openSnackBar("لايوجد قيمة للمصحة")
      return;
   }
    this.paymentService
      .pay(
        this.GateWayCommision.toString(),
        this.paymentFormGroup.value.year,
        this.paymentFormGroup.value.month,
        this.paymentFormGroup.value.email,
        this.paymentFormGroup.value.phone

      )
      .subscribe(
        (data) => this.OpenUrl(data),
        (err) => {
          ;
          this.helperService.openSnackBar(err.error)

        }
      );
  }
  OpenUrl(data) {

    if (data.result == "success"){
      window.open(data.url, "_blank");
    this.dialogRef.close()
    }
  }
}
