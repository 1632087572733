import { NavigationItem } from 'src/@tamam/interfaces/navigation-item.interface';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationLink } from '../../interfaces/navigation-item.interface';
import { PopoverService } from '../../components/popover/popover.service';
import { Observable, of } from 'rxjs';
import { UserMenuComponent } from '../../components/user-menu/user-menu.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../../components/search-modal/search-modal.component';
import { AesEncryptDecryptService } from 'src/app/core/services/shared/aes-encrypt-decrypt.service';

@Component({
  selector: 'tamam-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ =''
  user :any
  imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));
  userVisible$ = this.configService.config$.pipe(map(config => config.sidenav.user.visible));
  searchVisible$ = this.configService.config$.pipe(map(config => config.sidenav.search.visible));
  ClinicLogo=""
  userMenuOpen$: Observable<boolean> = of(false);

  items:NavigationItem []

  constructor(private navigationService: NavigationService,
              private layoutService: LayoutService,
              private configService: ConfigService,
              private readonly popoverService: PopoverService,
              private aesEncryptDecryptService:AesEncryptDecryptService,
              private readonly dialog: MatDialog) { }

  ngOnInit() {
var  clinic =JSON.parse(this.aesEncryptDecryptService.decrypt(sessionStorage.getItem("clinic")));
    this.title$= clinic.ClinicName
    this.ClinicLogo=clinic.Logo;
    this.user=sessionStorage.getItem("username")
    let  itemss: NavigationItem[];
    itemss = [


      {
        type: 'subheading',
        label: 'التمام',
        children: [
          {
            type: 'dropdown',
            label: 'إدارة مستخدمي النظام',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'link',
                label: 'إدارة مستخدمي النظام',
                formName:"frmUserManager",
                projectName:"UserManager",
                route: '/dashboard/users/list',
              },
              {
                type: 'link',
                label: 'الصلاحيات الرئيسية',
                formName:"frmGeneralPerm",
                projectName:"UserManager",
                route: '/dashboard/users/main-permission'
              },

            ]
          },
          {
            type: 'dropdown',
            label: 'الشؤون الادارية',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'dropdown',
                label: 'إدارةالموظفين',
                children: [
                  {
                    type: 'link',
                    label: 'البيانات الاساسية',
                    route: '/dashboard/employee/list',
                    formName: "frmEmployeeManage",
                    projectName: "Employee",

                  },
                  {
                    type: 'link',
                    formName: "frmOccupationInfo",
                    projectName: "Employee",
                    label: 'البيانات الوظيفية',
                    route: '/dashboard/employee/occupation-info',
                  },
                  {
                    type: 'link',
                    label: 'إنهاء الخدمة',
                    formName: "frmFinishWork",
                    projectName: "Employee",
                    route: '/dashboard/employee/finish-work',
                  }
                ]
              },
              {
                type: 'dropdown',
                label: 'الحضور والانصراف',
                children: [
                  {
                    type: 'link',
                    label: 'الحضور والانصراف',
                    route: '/dashboard/time-sheet',
                    formName:"frmTimeSheetManage",
                    projectName:"Employee",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'التقريرالشهري',
                    formName:"frmTimeSheetMonth",
                    projectName:"Employee",
                    route: '/dashboard/time-sheet/time-sheet-monthly',
                  },

                ]
              },
              {
                type: 'dropdown',
                label: 'الإجازات',
                children: [
                  {
                    type: 'link',
                    label: 'الإجازات',
                    formName:"frmVacation",
                    projectName:"Employee",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },


                ]
              },
              {
                type: 'link',
                label: 'الوحدات التنظيمية',
                route: '/dashboard/employee/organization-chart-management',
                formName: "frmOrganizationChartManager",
                projectName: "Employee",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'العطلات الرسمية',
                route: '/ui/page-layouts/card',
                formName:"frmHoliDays",
                projectName:"Employee",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الجداول المساعدة',
                formName:"frmLckTables",
                projectName:"LckTables",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'التجهيزات',
                formName:"frmSetupEmployee",
                projectName:"Employee",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
            ]
          },

          {
            type: 'dropdown',
            label: 'الشؤون المالية',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'dropdown',
                label: 'المحاسبة',
                children: [
                  {
                    type: 'link',
                    label: 'الدليل المحاسبي',
                    formName:"frmAccountManager",
                    projectName:"Accounts",

                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'تكوين قيود يدوية',
                    formName:"frmManualGaid",
                    projectName:"Accounts",
                    route: '/ui/page-layouts/card/tabbed',
                  },
                  {
                    type: 'link',
                    label: 'عرض القيود المحاسبية',
                    formName:"frmGaidInquiry",
                    projectName:"Accounts",
                    route: '/ui/page-layouts/card/tabbed',
                  },
                  {
                    type: 'link',
                    label: 'كشف حساب',

                    formName:"frmAccountStatement",
                    projectName:"Accounts",
                    route: '/ui/page-layouts/card/tabbed',
                  },
                  {
                    type: 'link',
                    label: 'حسابات مرتبات الموظفين',
                    formName:"frmAccSalOfEmp",
                    projectName:"Accounts",
                    route: '/ui/page-layouts/card/tabbed',
                  },
                  {
                    type: 'link',
                    label: 'ميزان المراجعة',
                    formName:"frmBalanceReview",
                    projectName:"Accounts",
                    route: '/ui/page-layouts/card/tabbed',
                  },
                  {
                    type: 'link',
                    label: 'حساب مرتبات الموظفين',
                    formName:"frmAccSalOfEmp",
                    projectName:"Accounts",
                    route: '/ui/page-layouts/card/tabbed',
                  },
                  {
                    type: 'link',
                    projectName:'Accounts',
                    formName:'frmAccCoUser',
                    label: 'تحديد المستخدمين',
                    route: '/ui/page-layouts/card/tabbed',
                  },
                  {
                    type: 'link',
                    label: 'الجداول المساعدة',
                    formName:"frmLckTables",
                    projectName:"LckTables",
                    route: '/ui/page-layouts/card/tabbed',
                  },
                  {
                    type: 'link',
                    label: 'التجهيزات',
                    formName:"frmAccountSetup",
                    projectName:"Accounts",
                    route: '/ui/page-layouts/card/tabbed',
                  }

                ]
              },
              {
                type: 'dropdown',
                label: 'النقدية',
                children: [
                  {
                    type: 'dropdown',
                    label: 'ايصال قبض',
                    children: [
                      {
                        type: 'link',
                        label: 'ايصال قبض مالي',
                        formName:"frmVoucherIn",
                        projectName:"Treasury",
                        route: '/dashboard/voucher-in-clinic/voucher-in',
                        routerLinkActiveOptions: { exact: true }
                      },
                      {
                        type: 'link',
                        label: 'إيرادات الخزينة',
                        formName:"frmVoucherInClinic",
                        projectName:"Treasury",
                        route: '/dashboard/voucher-in-clinic',
                        routerLinkActiveOptions: { exact: true }
                      },
                      {
                        type: 'link',
                        label: 'عرض وتعديل إيصال القبض',
                        formName:"frmVoucherInInquiry",
                        projectName:"Treasury",
                        route: '/dashboard/voucher-in-clinic/voucher-in-list',
                        routerLinkActiveOptions: { exact: true }
                      },
                      {
                        type: 'link',
                        label: 'دفاتر إيصالات القبض',
                        formName:"frmVoucherInInquiry",
                        projectName:"Treasury",
                        route: '/ui/page-layouts/card',
                        routerLinkActiveOptions: { exact: true }
                      },
                      {
                        type: 'link',
                        label: 'عرض إيصالات قبض الخزينة الفرعية',
                        formName:"frmVoucherInUnDo",
                        projectName:"Treasury",
                        route: '/ui/page-layouts/card',
                        routerLinkActiveOptions: { exact: true }
                      },

                    ]
                  },
                  {
                    type: 'dropdown',
                    label: 'ايصال الصرف',
                    children: [
                      {
                        type: 'link',
                        label: 'إيصال صرف مالي',
                        formName:"frmVoucherOut",
                        projectName:"Treasury",
                        route: '/dashboard/voucher-out',
                        routerLinkActiveOptions: { exact: true }
                      },
                      {
                        type: 'link',
                        label: 'عرض وتعديل إيصالات الصرف',
                        formName:"frmVoucherOutInquiry",
                        projectName:"Treasury",
                        route: '/dashboard/voucher-out/voucher-out-list',
                      },

                    ]
                  },
                  {
                    type: 'dropdown',
                    label: 'إيصال التحويل',
                    children: [
                      {
                        type: 'link',
                        label: 'إيصال تحويل',
                        route: '/dashboard/voucher-transfare/voucher-transfare',
                        formName:"frmVoucherTransfer",
                        projectName:"Treasury",
                        routerLinkActiveOptions: { exact: true }
                      },
                      {
                        type: 'link',
                        label: 'عرض وتعديل إيصالات التحويل',
                        formName:"frmVoucherTransferInquiry",
                        projectName:"Treasury",
                        route: '/dashboard/voucher-transfare/list',
                      },

                    ]
                  },
                  {
                    type: 'link',
                    label: 'أمناء الخزائن',
                    formName:"frmTreasuryTrustyMan",
                    projectName:"Treasury",
                    route: '/ui/page-layouts/card/tabbed',
                  },
                  {
                    type: 'link',
                    label: 'الجداول المساعدة',
                    formName:"frmLckTables",
                    projectName:"LckTables",
                    route: '/ui/page-layouts/card/tabbed',
                  },
                  {
                    type: 'link',
                    label: 'التجهيزات',
                    formName:"frmSetupTreasury",
                    projectName:"Treasury",
                    route: '/ui/page-layouts/card/tabbed',
                  },
                ]
              },
              {
                type: 'dropdown',
                label: 'المرتبات',
                children: [
                  {
                    type: 'dropdown',
                    label: 'السلف',
                    children: [
                      {
                        type: 'link',
                        label: 'سلفة جديدة',
                        route: '/ui/page-layouts/card',
                        formName:"frmSolfAddUpd",
                        projectName:"Salary",
                        routerLinkActiveOptions: { exact: true }
                      },
                      {
                        type: 'link',
                        label: 'السلف الاجتماعية',
                        route: '/ui/page-layouts/card',
                        formName:"frmSolf",
                        projectName:"Salary",
                        routerLinkActiveOptions: { exact: true }
                      },
                      {
                        type: 'link',
                        label: 'أقساط السلف',
                        formName:"frmSlfInstallment",
                        projectName:"Salary",
                        route: '/ui/page-layouts/card',
                        routerLinkActiveOptions: { exact: true }
                      },
                    ]
                  },
                  {
                    type: 'link',
                    label: 'ملفات الموظفين',
                    route: '/dashboard/employee/employee-salary',
                    formName:"frmEmployeeSystem",
                    projectName:"Salary",
                    routerLinkActiveOptions: { exact: true }
                  },

                  {
                    type: 'link',
                    label: 'تحضير المرتبات',
                    route: '/ui/page-layouts/card',
                    formName:"frmSalaryMaking",
                    projectName:"Salary",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'استفسارات',
                    formName:"frmSalaryInquiry",
                    projectName:"Salary",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'شهادة الراتب للمتعاونين',
                    route: '/ui/page-layouts/card',
                    formName:"frmSalaryWitness",
                    projectName:"Salary",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'تكوين حوافظ المرتبات',
                    formName:"frmSalaryHafedaMaking",
                    projectName:"Salary",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'عرض وتعديل حوافظ المرتبات',
                    route: '/ui/page-layouts/card',
                    formName:"frmSalaryHafedaInquiry",
                    projectName:"Salary",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'مزايا',
                    route: '/ui/page-layouts/card',
                    formName:"frmMeritEmployee",
                    projectName:"Salary",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'الجداول المساعدة',
                    formName:"frmLckTables",
                    projectName:"LckTables",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'التجهيزات',
                    formName:"frmSetupSalary",
                    projectName:"Salary",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  }
                ]
              },
              {
                type: 'dropdown',
                label: 'العملات',
                children: [
                  {
                    type: 'link',
                    label: 'إيصال شراء عملة',
                    formName:"frmVoucherIn",
                    projectName:"MoneyMang",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'عرض وتعديل إيصالات شراء العملة',
                    route: '/ui/page-layouts/card',
                    formName:"frmVoucherInInquiry",
                    projectName:"MoneyMang",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'الجداول المساعدة',
                    formName:"frmLckTables",
                    projectName:"LckTables",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },

                ]
              },

            ]
          },

          {
            type: 'dropdown',
            label: 'إدارة الأصناف والخدمات',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'link',
                label: 'إيصال شراء عملة',
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'عرض وتعديل إيصالات شراء العملة',
                route: '/ui/page-layouts/card',
                formName: "frmVoucherInInquiry",
                projectName: "MoneyMang",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الجداول المساعدة',
                route: '/ui/page-layouts/card',
                formName: "frmLckTables",
                projectName: "LckTables",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الأصناف والخدمات',
                formName:"frmItemManager",
                projectName:"Item",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الخدمات الاخرى',
                formName:"frmPublicServices",
                projectName:"Item",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'تسعيرة مجموعة خدمية',
                formName:"frmItemGroup",
                projectName:"Item",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'مكونات الاصناف',
                formName:"frmRawItem",
                projectName:"Item",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الخدمات الممنوعة للشركات',
                route: '/ui/page-layouts/card',
                formName:"frmItemForbidden",
                projectName:"Item",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الفهارس الممنوعة',
                formName:"frmForbiddenItemCatg",
                projectName:"LckTables",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'تسعيرة تقديم خاصة',
                formName:"frmItemOfrEmp",
                projectName:"Item",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الأصناف الراكدة',
                formName:"frmInactiveItem",
                projectName:"Item",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'تحميل من ملف إكسل',
                formName:"frmUpdFromExcel",
                projectName:"Item",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الجداول المساعدة',
                formName:"frmLckTables",
                projectName:"LckTables",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'التجهيزات',
                formName:"frmSetupItem",
                projectName:"Item",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              }
            ]
          },

          {
            type: 'dropdown',
            label: 'المشتريات',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'dropdown',
                label: 'راجع من المشتريات',
                children: [
                  {
                    type: 'link',
                    label: 'فاتورة راجع من المشتريات',
                    formName:"frmInvoiceInRe",
                    projectName:"InvoiceIn",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'عرض فواتر الراجع من المشتريات',
                    route: '/ui/page-layouts/card',
                    formName:"frmInvoiceInInquiry",
                    projectName:"InvoiceIn",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'استفسار تفصيلي راجع من المشتريات',
                    route: '/ui/page-layouts/card',
                    formName:"frmInvoiceInReInquiryDetails",
                    projectName:"InvoiceIn",
                    routerLinkActiveOptions: { exact: true }
                  },
                ]
              },
              {
                type: 'link',
                label: 'فاتورة مشتريات',
                formName:"frmInvoiceIn",
                projectName:"InvoiceIn",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },

              {
                type: 'link',
                label: 'تحضير المرتبات',
                route: '/ui/page-layouts/card',
                formName: "frmSalaryMaking",
                projectName: "Salary",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'استفسارات',
                route: '/ui/page-layouts/card',
                formName: "frmSalaryInquiry",
                projectName: "Salary",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'شهادة الراتب للمتعاونين',
                route: '/ui/page-layouts/card',
                formName: "frmSalaryWitness",
                projectName: "Salary",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'تكوين حوافظ المرتبات',
                formName: "frmSalaryHafedaMaking",
                projectName: "Salary",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'عرض وتعديل حوافظ المرتبات',
                route: '/ui/page-layouts/card',
                formName: "frmSalaryHafedaInquiry",
                projectName: "Salary",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'مزايا',
                route: '/ui/page-layouts/card',
                formName: "frmMeritEmployee",
                projectName: "Salary",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الجداول المساعدة',
                formName: "frmLckTables",
                projectName: "LckTables",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'التجهيزات',
                formName: "frmSetupSalary",
                projectName: "Salary",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              }
            ]
          },

          //
          {
            type: 'dropdown',
            label: 'المبيعات',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'dropdown',
                label: 'راجع من المبيعات',
                children: [
                  {
                    type: 'link',
                    label: 'فاتورة راجع من المبيعات',
                    route: '/ui/page-layouts/card',
                    formName:"frmInvoiceOutRe",
                    projectName:"InvoiceOut",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'عرض فواتر الراجع من المبيعات',
                    route: '/ui/page-layouts/card',
                    formName:"frmInvoiceOutReInquiry",
                    projectName:"InvoiceOut",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'استفسار تفصيلي راجع من المبيعات',
                    route: '/ui/page-layouts/card',
                    formName:"frmInvoiceOutReInquiryDetails",
                    projectName:"InvoiceOut",
                    routerLinkActiveOptions: { exact: true }
                  },
                ]
              },
              {
                type: 'link',
                label: 'فاتورة المبيعات',
                formName:"frmInvoiceOut",
                projectName:"InvoiceOut",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'مبيعات باركود',
                route: '/ui/page-layouts/card',
                formName:"frmInvoiceOutRetail",
                projectName:"InvoiceOut",
                routerLinkActiveOptions: { exact: true }
              },

              {
                type: 'link',
                label: 'عرض فواتير المبيعات',
                formName:"frmInvoiceOutInquiry",
                projectName:"InvoiceOut",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'استفسار تفصيلي المبيعات',
                route: '/ui/page-layouts/card',
                formName:"frmInvoiceOutInquiryDetails",
                projectName:"InvoiceOut",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الأرقام التسلسلية',
                route: '/ui/page-layouts/card',
                formName:"frmInvoiceOutDetSNInfo",
                projectName:"InvoiceOut",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'تقرير المبيعات',
                formName:"frmInvoOutStatistic",
                projectName:"InvoiceOut",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'مستخدمي تصنيف الزبائن',
                formName:"frmUsersOnlyUseCustomerType",
                projectName:"InvoiceOut",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'مستخدمي أنواع الفواتير',
                formName:"frmInvoOutTypeUser",
                projectName:"InvoiceOut",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الجداول المساعدة',
                route: '/ui/page-layouts/card',
                formName:"frmLckTables",
                projectName:"LckTables",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'التجهيزات',
                route: '/ui/page-layouts/card',
                formName:"frmSetupInvoiceOut",
                projectName:"InvoiceOut",
                routerLinkActiveOptions: { exact: true }
              }
            ]
          },
          {
  type: 'dropdown',
  label: 'المخازن',
  icon: 'mat:view_compact',
  children: [
    {
      type: 'dropdown',
      label: 'إذن استلام',
      children: [
        {
          type: 'link',
          label: 'إذن إستلام',
          formName:"frmReceiptIn",
          projectName:"Store",
          route: '/ui/page-layouts/card',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'إذن إستلام لفاتورة',
          formName:"frmReceiptInForInvoiceIn",
          projectName:"Store",
          route: '/ui/page-layouts/card/tabbed',
        },
        {
          type: 'link',
          label: 'عرض أذونات الاستلام',
          formName: "frmReceiptInInquiry",
          projectName: "Store",
          route: '/ui/page-layouts/card/tabbed',
        },
        {
          type: 'link',


          label: 'استفسار تفصيلي لأذونات الاستلام',
          formName: "frmReceiptInInquiryDeatials",
          projectName: "Store",
          route: '/ui/page-layouts/card/tabbed',
        },
        {
          type: 'link',
          label: 'إذن ترجيع من الاستلام',
          formName:"frmReceiptInRe",
          projectName:"Store",
          route: '/ui/page-layouts/card/tabbed',
        },
        {
          type: 'link',
          label: 'عرض أذونات الترجيع من الاستلام',
          formName:"frmReceiptInReInquiry",
          projectName:"Store",
          route: '/ui/page-layouts/card/tabbed',
        },
        {
          type: 'link',
          formName:"frmReceiptInReInquiryDeatials",
          projectName:"Store",
          label: 'استفسار تفصيلي لأذونات الراجع من الاستلام',
          route: '/ui/page-layouts/card/tabbed',
        }
      ]
    },
    {
      type: 'dropdown',
      label: 'إذن صرف',
      children: [
        {
          type: 'link',
          label: 'إذن صرف',
          formName:"frmReceiptOut",
          projectName:"Store",
          route: '/ui/page-layouts/card',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'إذن صرف لفاتورة',
          formName:"frmReceiptOutForInvoice",
          projectName:"Store",
          route: '/ui/page-layouts/card/tabbed',
        },
        {
          type: 'link',
          label: 'عرض أذونات الصرف',
          projectName:"Store",
          formName:"frmReceiptOutInquiry",
          route: '/ui/page-layouts/card/tabbed',
        },
        {
          type: 'link',
          label: 'استفسار تفصيلي لأذونات الصرف',
          formName:"frmReceiptOutInquiryDeatials",
          projectName:"Store",
          route: '/ui/page-layouts/card/tabbed',
        },
        {
          type: 'link',
          label: 'إذن ترجيع من الصرف',
          route: '/ui/page-layouts/card/tabbed',
          formName:"frmReceiptOutRe",
          projectName:"Store",
        },
        {
          type: 'link',
          label: 'اذن راجع من الصرف لفاتورة',
          route: '/ui/page-layouts/card/tabbed',
          formName:"frmReceiptOutReForInvoice",
          projectName:"Store",
        },
        {
          type: 'link',
          label: 'عرض أذونات الترجيع من الصرف',
          formName:"frmReceiptOutReInquiry",
          projectName:"Store",
          route: '/ui/page-layouts/card/tabbed',
        },
        {
          type: 'link',
          label: 'استفسار تفصيلي لأذونات الراجع من الصرف',
          formName:"frmReceiptOutReInquiryDeatials",
          projectName:"Store",
          route: '/ui/page-layouts/card/tabbed',
        }
      ]
    },
    {
      type: 'dropdown',
      label: 'إذن تحويل',
      children: [
        {
          type: 'link',
          label: 'إذن تحويل',
          formName:"frmReceiptTransfer",
          projectName:"Store",

          route: '/ui/page-layouts/card',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'عرض أذونات التحويل',
          formName:"frmReceiptTransferInquiry",
          projectName:"Store",
          route: '/ui/page-layouts/card/tabbed',
        },
        {
          type: 'link',
          formName:"frmReceiptTransferInquiryDetails",
          projectName:"Store",
          label: 'استفسار تفصيلي لأذونات التحويل',
          route: '/ui/page-layouts/card/tabbed',
        }

      ]
    },
    {
      type: 'link',
      label: 'الكميات بالمخازن',
      formName:"frmItemQuantity",
      projectName:"Store",
      route: '/ui/page-layouts/card',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'link',
      label: 'حركة صنف',
      formName:"frmItemMovement",
      projectName:"Store",
      route: '/ui/page-layouts/card',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'link',
      label: 'استفسار المستلم والمنصرف',
      formName:"frmItemInOutInquiry",
      projectName:"Store",
      route: '/ui/page-layouts/card',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'link',
      label: 'الأصناف الغير مسحوبة',
      formName:"frmItemNotDistributed",
      projectName:"Store",
      route: '/ui/page-layouts/card',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'link',
      label: 'أمناء المخازن',
      formName:"frmTrustyMan",
      projectName:"Store",
      route: '/ui/page-layouts/card',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'link',
      label: 'طلبية المخازن',
      formName:"frmStoreOrder",
      projectName:"Store",
      route: '/ui/page-layouts/card',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'link',
      label: 'عرض الطلبيات',
      formName:"frmStoreOrderInquiry",
      projectName:"Store",
      route: '/ui/page-layouts/card',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'link',
      label: 'مقارنة الكميات',
      formName:"frmCompare",
      projectName:"Store",
      route: '/ui/page-layouts/card',
      routerLinkActiveOptions: { exact: true }
    },

    {
      type: 'link',
      label: 'الجداول المساعدة',
      formName:"frmLckTables",
      projectName:"LckTables",
      route: '/ui/page-layouts/card',
      routerLinkActiveOptions: { exact: true }
    },
    {
      type: 'link',
      label: 'التجهيزات',
      formName:"frmSetupStore",
      projectName:"Store",
      route: '/ui/page-layouts/card',
      routerLinkActiveOptions: { exact: true }
    },
  ]
          },
          /////
          {
            type: 'dropdown',
            label: 'الفحوصات',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'dropdown',
                label: 'التحاليل الطبية',
                children: [
                  {
                    type: 'link',
                    label: 'نتائج التحاليل',
                    route: '/dashboard/analysis-result',
                    formName:"frmAnalysisResult",
                    projectName:"Analysis",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'مجموعات التحاليل',
                    formName:"frmAnalysisGroup",
                    projectName:"Analysis",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'إدارة التحاليل',
                    formName:"frmAnalysisManagement",
                    projectName:"Analysis",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'المعدلات الطبيعية',
                    route: '/ui/page-layouts/card',
                    formName:"frmAnalysisNormalRanges",
                    projectName:"Analysis",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'نتائج تحاليل الانسجة',
                    formName:"frmHistoResult",
                    projectName:"Analysis",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'عرض نتائج الانسجة',
                    formName:"frmHistoInq",
                    projectName:"Analysis",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'الجداول المساعدة',
                    formName:"frmLckTables",
                    projectName:"LckTables",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'التجهيزات',
                    formName:"frmSetupAnalysis",
                    projectName:"Analysis",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  }
                ]
              },
              {
                type: 'dropdown',
                label: 'الأشعة',
                children: [
                  {
                    type: 'link',
                    label: 'نتائج الاشعة',
                    route: '/ui/page-layouts/card',
                    formName:"frmRadiologyResult",
                    projectName:"Radiology",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'إدارة خدمات الاشعة',
                    formName:"frmRadiologyManagement",
                    projectName:"Radiology",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'إدارة خدمات تقارير الاشعة',
                    formName:"frmRadManagReport",
                    projectName:"Radiology",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'مجموعات الاشعة',
                    formName:"frmRadiologGroup",
                    projectName:"Radiology",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'الجداول المساعدة',
                    formName:"frmLckTables",
                    projectName:"LckTables",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },

                ]
              },
              {
                type: 'link',
                label: 'تكوين الطلبات',
                route: '/dashboard/analysis-result/analysis-orders',
                formName:"frmOrders",
                projectName:"Analysis",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'تقديم الخدمات',
                route: '/ui/page-layouts/card',
                formName:"frmOtherServiceProvider",
                projectName:"Analysis",
                routerLinkActiveOptions: { exact: true }
              },

              {
                type: 'link',
                label: 'عرض الخدمات المطلوبة',
                formName:"frmOrdersInquiry",
                projectName:"Analysis",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'المضاد الحيوي والبكتيريا',
                route: '/ui/page-layouts/card',
                formName:"frmCultureStatistic",
                projectName:"Analysis",
                routerLinkActiveOptions: { exact: true }
              },

            ]
          },
          ////
          {
            type: 'dropdown',
            label: 'الاستعلامات',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'link',
                label: 'الاستعلامات',
                formName:"frmReception",
                projectName:"Reception",
                route: '/dashboard/reception',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'جدول العيادات الخارجية',
                route: '/ui/page-layouts/card',
                formName:"frmOpdSchedule",
                projectName:"Reception",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'فتح ملف مريض',
                route: '/ui/page-layouts/card',
                formName:"frmPatientsFileAddUpd",
                projectName:"Reception",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'عرض وتعديل ملفات المرضى',
                route: '/ui/page-layouts/card',
                formName:"frmPatientsFile",
                projectName:"Reception",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'إيقاف الحجز',
                route: '/ui/page-layouts/card',
                formName:"frmStopAppoint",
                projectName:"Reception",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'تحديد المستخدمين',
                route: '/ui/page-layouts/card',
                formName:"frmUserAppointOpd",
                projectName:"Reception",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'دمج ملفين',
                formName:"frmMargeTowFiles",
                projectName:"Reception",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الجهات المتعاقد معها',
                formName:"frmContractCo",
                projectName:"Reception",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },

              {
                type: 'link',
                label: 'الجداول المساعدة',
                route: '/ui/page-layouts/card',
                formName:"frmLckTables",
                projectName:"LckTables",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'التجهيزات',
                route: '/ui/page-layouts/card',
                formName:"frmSetupReception",
                projectName:"Reception",
                routerLinkActiveOptions: { exact: true }
              }
            ]
          },
          ///
          {
            type: 'dropdown',
            label: 'العيادات الخارجية',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'link',
                label: 'زيارة مريض',
                route: '/ui/page-layouts/card',
                formName:"frmOpdPatientVisit",
                projectName:"OPD",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'إحصائيات تشخيصية',
                route: '/ui/page-layouts/card',
                formName:"frmOpdDiagnosisInq",
                projectName:"OPD",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'عرض الزيارات',
                formName:"frmOpdVisitInq",
                projectName:"OPD",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'التجهيزات',
                route: '/ui/page-layouts/card',
                formName:"frmSetupOpd",
                projectName:"OPD",
                routerLinkActiveOptions: { exact: true }
              },

            ]
          },
          {
            type: 'dropdown',
            label: 'الموردون والزبائن',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'link',
                label: 'الزبائن',
                formName:"frmCustomerManager",
                projectName:"CustomerAndSupplier",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الموردين',
                formName:"frmSupplierManager",
                projectName:"CustomerAndSupplier",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label:'الجداول المساعدة',
                formName:"frmLckTables",
                projectName:"LckTables",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
            ]
          },
          {
            type: 'dropdown',
            label: 'فواتير مطالبة العملاء',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'link',
                label: 'فواتير المطالبات',
                route: '/ui/page-layouts/card',
                formName:"frmAgentClaim",
                projectName:"InvoiceOut",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'عرض المطالبات',
                formName:"frmAgentClaimInquiry",
                projectName:"InvoiceOut",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label:'عرض تفصيلي',
                formName:"frmAgentClaimInqDet",
                projectName:"InvoiceOut",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label:'مستخدمي مراكز الخدمة',
                formName:"frmUsrProvider",
                projectName:"InvoiceOut",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label:'تحديد الخدمات',
                formName:"frmAgentItemProv",
                projectName:"InvoiceOut",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label:'الجداول المساعدة',
                formName:"frmLckTables",
                projectName:"LckTables",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
            ]
          },
          {
            type: 'dropdown',
            label: 'الإيواء',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'dropdown',
                label: 'الطبيب',
                children: [
                  {
                    type: 'link',
                    label: 'مرور الطبيب',
                    formName:"frmDocRound",
                    projectName:"InPatient",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  }


                ]
              },
              {
                type: 'dropdown',
                label: 'التمريض',
                children: [
                  {
                    type: 'link',
                    label: 'مرور التمريض',
                    formName:"frmNrsRound",
                    projectName:"InPatient",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'الادوية المصروفة',
                    formName:"frmInPMedicine",
                    projectName:"InPatient",
                    route: '/ui/page-layouts/card/tabbed',
                  },

                ]
              },
              {
                type: 'dropdown',
                label: 'العمليات',
                children: [
                  {
                    type: 'link',
                    label: 'عملية جراحية',
                    formName:"frmOperation",
                    projectName:"InPatient",
                    route: '/dashboard/oparation',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'عرض العمليات',
                    formName:"frmOperationInquiry",
                    projectName:"InPatient",
                    route: '/dashboard/oparation/oparation-list',
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'التخدير',
                    route: '/ui/page-layouts/card',
                    formName:"frmAnesthesia",
                    projectName:"InPatient",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'مواعيد العمليات',
                    route: '/ui/page-layouts/card',
                    formName:"frmOpReserve",
                    projectName:"InPatient",
                    routerLinkActiveOptions: { exact: true }
                  },
                  {
                    type: 'link',
                    label: 'إدارة العمليات',
                    formName:"frmOpServices",
                    projectName:"InPatient",
                    route: '/ui/page-layouts/card',
                    routerLinkActiveOptions: { exact: true }
                  },

                ]
              },

              {
                type: 'link',
                label: 'إيواء مريض',
                route: '/ui/page-layouts/card',
                formName:"frmPatientAdmission",
                projectName:"InPatient",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'ورقة خروج',
                route: '/ui/page-layouts/card',
                formName:"frmPatientDischarge",
                projectName:"InPatient",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'حجز سرير',
                route: '/ui/page-layouts/card',
                formName:"frmBedReserve",
                projectName:"InPatient",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'تحديد الموظفين',
                formName:"frmOpTeam",
                projectName:"InPatient",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'عرض مرضى الايواء',
                formName:"frmInPatientInquiry",
                projectName:"InPatient",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'تجهيز غرف الايواء',
                formName:"frmBedService",
                projectName:"InPatient",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'إحصائيات تشخيصية',
                formName:"frmInpDiagnosisInq",
                projectName:"InPatient",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'إدارة الوحدات الطبية',
                route: '/ui/page-layouts/card',
                formName:"frmInpUint",
                projectName:"InPatient",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'إجازة مريض إيواء',
                formName:"frmInpGoOut",
                projectName:"InPatient",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الجداول المساعدة',
                route: '/ui/page-layouts/card',
                formName:"frmLckTables",
                projectName:"LckTables",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'التجهيزات',
                route: '/ui/page-layouts/card',
                formName:"frmSetupInPatient",
                projectName:"InPatient",
                routerLinkActiveOptions: { exact: true }
              },

            ]
          },
          {
            type: 'dropdown',
            label: 'المصنع',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'link',
                label: 'دورة انتاجية',
                formName:"frmCycle",
                projectName:"Factory",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'عرض البيانات',
                formName:"frmCycleInquiry",
                projectName:"Factory",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الجداول المساعدة',
                route: '/ui/page-layouts/card',
                formName:"frmLckTables",
                projectName:"LckTables",
                routerLinkActiveOptions: { exact: true }
              },


            ]
          },
          {
            type: 'dropdown',
            label: 'المهام',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'link',
                label: 'مهمة جديدة',
                formName:"frmTaskAddUpd",
                projectName:"Task",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'عرض المهام',
                formName:"frmTaskInquiry",
                projectName:"Task",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'إدارة أنواع المهام',
                formName:"frmTaskPerm",
                projectName:"Task",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الجداول المساعدة',
                route: '/ui/page-layouts/card',
                formName:"frmLckTables",
                projectName:"LckTables",
                routerLinkActiveOptions: { exact: true }
              },


            ]
          },
          {
            type: 'dropdown',
            label: 'العلاج الطبيعي',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'link',
                label: 'المشرف',
                formName:"frmSupervisor",
                projectName:"Physiotherapy",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'جلسة علاج',
                formName:"frmProvideSession",
                projectName:"Physiotherapy",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'التجهيزات',
                route: '/ui/page-layouts/card',
                formName:"frmSetupPhysiotherapy",
                projectName:"Physiotherapy",
                routerLinkActiveOptions: { exact: true }
              },



            ]
          },
          {
            type: 'dropdown',
            label: 'الكلى',
            icon: 'mat:view_compact',
            children: [
              {
                type: 'link',
                label: 'مستخدمي مراكز الخدمة',
                route: '/ui/page-layouts/card',
                formName:"frmUsrProvider",
                projectName:"InvoiceOut",
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'مواعيد جلسات الغسيل',
                formName:"frmKidnyScheduleAddUpd",
                projectName:"Kidney",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'عرض المواعيد',
                formName:"frmKidnySchedule",
                projectName:"Kidney",
                route: '/ui/page-layouts/card',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'الجداول المساعدة',
                route: '/ui/page-layouts/card',
                formName:"frmLckTables",
                projectName:"LckTables",
                routerLinkActiveOptions: { exact: true }
              },


            ]
          },
          {
            type: 'link',
            label: 'استفسار عن الخدمات',
            route: '/ui/page-layouts/card',
            formName:"frmClinicServiceInquiry",
            projectName:"Analysis",
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'التقرير التجاري',
            formName:"frmSalesInquiry",
            projectName:"InvoiceOut",
            route: '/ui/page-layouts/card',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'طلب خدمة',
            formName:"frmOrders",
            projectName:"Analysis",
            route: '/ui/page-layouts/card',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'تقديم الخدمات',
            formName:"frmOtherServiceProvider",
            projectName:"Analysis",
            route: '/ui/page-layouts/card',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'وصفات طبية',
            formName:"frmPrescriptionInquiry",
            projectName:"Analysis",
            route: '/ui/page-layouts/card',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'المطبخ',
            formName:"frmKitchenInfo",
            projectName:"InPatient",
            route: '/ui/page-layouts/card',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'ملف المريض',
            formName:"frmPatientFile",
            projectName:"OPD",
            route: '/ui/page-layouts/card',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Computers',
            route: '/ui/page-layouts/card',
            formName:"frmComputer",
            projectName:"Reception",
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'تتبع التعديلات',
            route: '/ui/page-layouts/card',
            formName:"frmTranLog",
            projectName:"Analysis",
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'وصفات النظارات',
            formName:"frmGlassesPreView",
            projectName:"Analysis",
            route: '/ui/page-layouts/card',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Sms Subscription',
            formName:"frmSmsSubscription",
            projectName:"SmsSubscription",
            route: '/dashboard/sms-subscription',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Sms Template',
            formName:"frmSmsTemplate",
            projectName:"SmsTemplate",
            route: '/dashboard/sms-subscription/sms-Template',
            routerLinkActiveOptions: { exact: true }
          },
        ]
      },


    ];
  this.navigationService.getIcons()
   .subscribe(
    async data =>{
     let  FormName = data.map(function(a) {return a.FormName;});

     this.items  = this.navigationService.configureItems(itemss,FormName);

   });;
  }

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: UserMenuComponent,
        origin,
        offsetY: -8,
        width: origin.clientWidth,
        position: [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom'
          }
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  openSearch(): void {
    this.dialog.open(SearchModalComponent, {
      panelClass: 'tamam-dialog-glossy',
      width: '100%',
      maxWidth: '600px'
    });
  }
}
