<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <meta content="width=device-width, initial-scale=1.0" name="viewport">

    <title>شركة النماء - لتقنية المعلومات</title>
    <meta content="" name="description">
    <meta content="" name="keywords">


    <link rel="shortcut icon" href="assets/Images/logo/photoic.png" />
    <style>
        .error {
            color: red;
        }
    </style>
</head>

<body style="direction:rtl">

    <!-- ======= Top Bar ======= -->
    <div id="topbar" class="d-flex align-items-center fixed-top">
        <div class="container d-flex justify-content-between">
            <div class="contact-info d-flex align-items-center">
                <i class="bi bi-envelope"></i> <a href="mailto:info@tamam.ly">info@tamam.ly</a>
                <i class="bi bi-phone"></i> <a style="direction: ltr;" href="tel:0911205100"> +218 91 120 5100</a>
                <i class="bi bi-phone"></i> <a style="direction: ltr;" href="tel:0911206100"> +218 91 120 6100</a>
            </div>
            <div class="d-none d-lg-flex social-links align-items-center">
                <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
                <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
                <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
                <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
            </div>
        </div>
    </div>

    <!-- ======= Header ======= -->
    <header id="header" class="fixed-top">
        <div class="container d-flex align-items-center">

            <h1 class="logo right-atuo"><a href="index.html"><img src="/assets/Images/logo/main-logo.png" /></a></h1>
            <!-- Uncomment below if you prefer to use an image logo -->
            <!-- <a href="index.html" class="logo me-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

            <nav id="navbar" class="navbar order-last order-lg-0">
                <ul>
                    <li><a class="nav-link scrollto active" href="#hero">الرئيسية</a></li>
                    <li><a class="nav-link scrollto" href="#about">عن الشركة</a></li>
                    <li><a class="nav-link scrollto" href="#services">خدماتنا</a></li>


                    <li><a class="nav-link scrollto" href="#contact">اتصل بنا</a></li>
                </ul>
                <i class="bi bi-list mobile-nav-toggle"></i>
            </nav>

            <a [routerLink]="['./clinic']" class="appointment-btn scrollto"><span class="d-md-inline">المراكز الطبية</span></a>

        </div>
    </header><!-- End Header appointment-btn scrollto
  ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center">
        <div class="container">
            <h1>شركة النماء</h1>
            <h2>لتقنية المعلومات</h2>
        </div>
    </section><!-- End Hero -->

    <main id="main">

        <!-- ======= Why Us Section ======= -->
        <section id="why-us" class="why-us">
            <div class="container">

                <div class="row">
                    <div class="col-lg-4 d-flex align-items-stretch">
                        <div class="content">
                            <h3>من نحن</h3>
                            <p>
                                شركة النماء لتقنية المعلومات الآلي شركة ليبية رائدة في مجال تطوير الانظمة البرمجية   تاسست الشركة عام 2000 ، مقرها الرئيسي في منطقة حي دمشق بطرابلس ، تعمل الشركة في تطوير الانظمة البرمجية في مجال الخدمات الصحية والتجارية  ، الشركة اسسها مجموعة من المبرمجين و المهندسين اليبيين ،تم اطلاق اول نظام لنا عام 2004 و تم  تركيبه في مصحة لجراحة العظام بطرابلس –  والآن وبحمد الله  يعمل نظامنا في اغلب المصحات الكبرى داخل ليبيا.
                            </p>
                            <div class="text-center">
                                <a href="#" class="more-btn">
                                    اعرف المزيد <i class="bx bx-chevron-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 d-flex align-items-stretch">
                        <div class="icon-boxes d-flex flex-column justify-content-center">
                            <div class="row">
                                <div class="col-xl-4 d-flex align-items-stretch">
                                    <div class="icon-box mt-4 mt-xl-0">
                                        <i class="bx bx-receipt"></i>
                                        <h4>رؤيتنا</h4>
                                        <p>
                                            رؤيتنا
                                            تطوير البنية التحتية للانظمة الصحية في ليبيا بالكامل و انشاء قاعدة بيانات موحدة لها
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xl-4 d-flex align-items-stretch">
                                    <div class="icon-box mt-4 mt-xl-0">
                                        <i class="bx bx-cube-alt"></i>
                                        <h4>مهتمنا</h4>
                                        <p>دعم النظام الصحي تقنيا و تطوير نظام الكتروني يلبي احتياجات المراكز الصحية بالكمال </p>
                                    </div>
                                </div>
                                <div class="col-xl-4 d-flex align-items-stretch">
                                    <div class="icon-box mt-4 mt-xl-0">
                                        <i class="bx bx-images"></i>
                                        <h4>قيمنا</h4>
                                        <p>الجودة، الامانة، الالتزام، الدقة ،التطوير المستمر</p>
                                    </div>
                                </div>
                            </div>
                        </div><!-- End .content-->
                    </div>
                </div>

            </div>
        </section><!-- End Why Us Section -->
        <!-- ======= About Section ======= -->
        <section id="about" class="about">
            <div class="container-fluid">

                <div class="row">
                    <div class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative">
                        <a href="https://www.youtube.com/watch?v=l0ADFV4eTvg" class="glightbox play-btn mb-4"></a>
                    </div>

                    <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                        <h3>ما يميزنا</h3>
                        <p>تقدم شركة النماء لتقنية المعلومات خدماتها في برمجة و تطوير الانظمة الالكترونية في عدة مجالات منها الادارة و الصحة و المنظومات المالية و تشتهر شركتنا بالانظمة الصحية حيث تعمل انظمتنا في اكثر من 100 مصحة  داخل ليبيا </p>

                        <div class="icon-box">
                            <div class="icon"><i class="bx bx-fingerprint"></i></div>
                            <h4 class="title"><a href="">فريق مميز</a></h4>
                            <p class="description">نمتلك نخبة من المبرمجين و محللي الانظمة و قواعد البيانات الذين تفخر الشركة بهم</p>
                        </div>

                        <div class="icon-box">
                            <div class="icon"><i class="bx bx-gift"></i></div>
                            <h4 class="title"><a href="">نهتم بأدق التفاصيل</a></h4>
                            <p class="description">الانظمة المستقر تحتاج الى الاحاطة بكل تفاصيل المجال المراد تصميم النظام له نحن نهتم بادق التفاصيل لنحقق المطلوب</p>
                        </div>

                        <div class="icon-box">
                            <div class="icon"><i class="bx bx-atom"></i></div>
                            <h4 class="title"><a href="">الخبرة الطويلة</a></h4>
                            <p class="description">خبرتنا في المجال فاقت العشرين سنة و انظمتنا تعمل عند المئات من العملاء</p>
                        </div>

                    </div>
                </div>

            </div>
        </section><!-- End About Section -->
        <!-- ======= Counts Section ======= -->
        <section id="counts" class="counts">
            <div class="container">

                <div class="row">

                    <div class="col-lg-4 col-md-6">
                        <div class="count-box">
                            <i class="fas fa-user-md"></i>
                            <span>خبرتنا</span><span data-purecounter-start="0" data-purecounter-end="20" data-purecounter-duration="1" class="purecounter">{{experincecount}}</span>
                            <p>سنة</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 mt-5 mt-md-0">
                        <div class="count-box">
                            <i class="far fa-hospital"></i>
                            <span>عملائنا</span> <span data-purecounter-start="0" data-purecounter-end="100" data-purecounter-duration="1" class="purecounter">{{clientcount}}</span>
                            <p>مصحة</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 mt-5 mt-lg-0">
                        <div class="count-box">
                            <i class="fas fa-flask"></i>
                            <span>المدن التي نغطيها</span><span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" class="purecounter">{{citycount}}</span>
                            <p>مدينة</p>
                        </div>
                    </div>

                    <!--  <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                    <div class="count-box">
                        <i class="fas fa-award"></i>
                        <span>+</span><span data-purecounter-start="0" data-purecounter-end="150" data-purecounter-duration="1" class="purecounter"></span>
                        <p>مستخدم</p>
                    </div>
                          </div>-->

                </div>

            </div>
        </section><!-- End Counts Section -->
        <!-- ======= Services Section ======= -->
        <section id="services" class="services">
            <div class="container">

                <div class="section-title">
                    <h2>خدماتنا</h2>
                    <p>تقدم شركة النماء لتقنية المعلومات خدماتها في برمجة و تطوير الانظمة الالكترونية في عدة مجالات منها الادارة و الصحة و المنظومات المالية و تشتهر شركتنا بالانظمة الصحية حيث تعمل انظمتنا في اكثر من 100 مصحة  داخل ليبيا. </p>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="icon-box">
                            <div class="icon"><i class="fas fa-heartbeat"></i></div>
                            <h4><a href="">برمجة النظام الصحي</a></h4>
                            <p>منظومة التمام هي منظومة تغطي جميع متطلبات المراكز الصحية الطبية المالية الادارية</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                        <div class="icon-box">
                            <div class="icon"><i class="fas fa-pills"></i></div>
                            <h4><a href="">دعم تقني 24 / 7</a></h4>
                            <p>بالاضافة ان انظمتنا انظمة مستقرة الا اننا نقدم الدعم الفني و التقني بشكل مستمر</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                        <div class="icon-box">
                            <div class="icon"><i class="fas fa-hospital-user"></i></div>
                            <h4><a href="">منظومات تجارية و صحية</a></h4>
                            <p>تقدم الشركة خدمات مخصصة في برمجة الانظمة التجارية و تطوير المنظومات الصحية</p>
                        </div>
                    </div>

                    <!--   <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                           <div class="icon-box">
                               <div class="icon"><i class="fas fa-notes-medical"></i></div>
                               <h4><a href="">Divera don</a></h4>
                               <p>Modi nostrum vel laborum. Porro fugit error sit minus sapiente sit aspernatur</p>
                           </div>
                       </div>-->

                </div>

            </div>
        </section><!-- End Services Section -->
        <!-- ======= Appointment Section ======= -->
        <!--  <section id="appointment" class="appointment section-bg">
              <div class="container">

                  <div class="section-title">
                      <h2>Make an Appointment</h2>
                      <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
                  </div>

                  <form action="forms/appointment.php" method="post" role="form" class="php-email-form">
                      <div class="row">
                          <div class="col-md-4 form-group">
                              <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars">
                              <div class="validate"></div>
                          </div>
                          <div class="col-md-4 form-group mt-3 mt-md-0">
                              <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email">
                              <div class="validate"></div>
                          </div>
                          <div class="col-md-4 form-group mt-3 mt-md-0">
                              <input type="tel" class="form-control" name="phone" id="phone" placeholder="Your Phone" data-rule="minlen:4" data-msg="Please enter at least 4 chars">
                              <div class="validate"></div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-4 form-group mt-3">
                              <input type="datetime" name="date" class="form-control datepicker" id="date" placeholder="Appointment Date" data-rule="minlen:4" data-msg="Please enter at least 4 chars">
                              <div class="validate"></div>
                          </div>
                          <div class="col-md-4 form-group mt-3">
                              <select name="department" id="department" class="form-select">
                                  <option value="">Select Department</option>
                                  <option value="Department 1">Department 1</option>
                                  <option value="Department 2">Department 2</option>
                                  <option value="Department 3">Department 3</option>
                              </select>
                              <div class="validate"></div>
                          </div>
                          <div class="col-md-4 form-group mt-3">
                              <select name="doctor" id="doctor" class="form-select">
                                  <option value="">Select Doctor</option>
                                  <option value="Doctor 1">Doctor 1</option>
                                  <option value="Doctor 2">Doctor 2</option>
                                  <option value="Doctor 3">Doctor 3</option>
                              </select>
                              <div class="validate"></div>
                          </div>
                      </div>

                      <div class="form-group mt-3">
                          <textarea class="form-control" name="message" rows="5" placeholder="Message (Optional)"></textarea>
                          <div class="validate"></div>
                      </div>
                      <div class="mb-3">
                          <div class="loading">Loading</div>
                          <div class="error-message"></div>
                          <div class="sent-message">Your appointment request has been sent successfully. Thank you!</div>
                      </div>
                      <div class="text-center"><button type="submit">Make an Appointment</button></div>
                  </form>

              </div>
          </section>-->
        <!-- End Appointment Section -->

        <!-- ======= Testimonials Section ======= -->
        <!-- <section id="   " class="testimonials">
             <div class="container">

                 <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                     <div class="swiper-wrapper">

                         <div class="swiper-slide">
                             <div class="testimonial-wrap">
                                 <div class="testimonial-item">
                                     <img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="">
                                     <h3>Saul Goodman</h3>
                                     <h4>Ceo &amp; Founder</h4>
                                     <p>
                                         <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                         Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                                         <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                                     </p>
                                 </div>
                             </div>
                         </div>

                         <div class="swiper-slide">
                             <div class="testimonial-wrap">
                                 <div class="testimonial-item">
                                     <img src="assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="">
                                     <h3>Sara Wilsson</h3>
                                     <h4>Designer</h4>
                                     <p>
                                         <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                         Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                                         <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                                     </p>
                                 </div>
                             </div>
                         </div>

                         <div class="swiper-slide">
                             <div class="testimonial-wrap">
                                 <div class="testimonial-item">
                                     <img src="assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="">
                                     <h3>Jena Karlis</h3>
                                     <h4>Store Owner</h4>
                                     <p>
                                         <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                         Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                                         <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                                     </p>
                                 </div>
                             </div>
                         </div>

                         <div class="swiper-slide">
                             <div class="testimonial-wrap">
                                 <div class="testimonial-item">
                                     <img src="assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="">
                                     <h3>Matt Brandon</h3>
                                     <h4>Freelancer</h4>
                                     <p>
                                         <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                         Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
                                         <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                                     </p>
                                 </div>
                             </div>
                         </div>

                         <div class="swiper-slide">
                             <div class="testimonial-wrap">
                                 <div class="testimonial-item">
                                     <img src="assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="">
                                     <h3>John Larson</h3>
                                     <h4>Entrepreneur</h4>
                                     <p>
                                         <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                         Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
                                         <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                                     </p>
                                 </div>
                             </div>
                         </div><
                     </div>
                     <div class="swiper-pagination"></div>
                 </div>

             </div>
         </section>-->
        <!-- End Testimonials Section -->

        <section id="contact" class="contact">
            <div class="container">

                <div class="section-title">
                    <h2>تواصل معنا</h2>
                    <p>تواصل معنا من خلال البريد الالكتروني أو الواتساب وسنرد عليك في أقرب وقت</p>
                </div>
            </div>

            <div style="width: 100;">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3351.6918479796223!2d13.200248684997087!3d32.85340978094903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13a89316090f6ab5%3A0xa9d2f72bbf4e628e!2z2K3ZiiDYr9mF2LTZgtiMINi32LHYp9io2YTYs9iMINmE2YrYqNmK2Kc!5e0!3m2!1sar!2snl!4v1661685954746!5m2!1sar!2snl" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style="border:0; width:100%!important; height: 350px;" ></iframe>
            </div>

            <div class="container">
                <div class="row mt-5">

                    <div class="col-lg-4">
                        <div class="info">
                            <div class="address">
                                <i class="bi bi-geo-alt"></i>
                                <h4>الموقع:</h4>
                                <p>طرابلس /حي دمشق</p>
                            </div>

                            <div class="email">
                                <i class="bi bi-envelope"></i>
                                <h4>البريد الالكتروني:</h4>
                                <p>info@tamam.ly</p>
                            </div>

                            <div class="phone">
                                <i class="bi bi-phone"></i>
                                <h4>رقم الاتصال:</h4>
                                <p>218913188833+</p>
                            </div>

                        </div>

                    </div>

                    <div class="col-lg-8 mt-5 mt-lg-0">

                        <form id="contactForm" role="form" class="php-email-form">
                            <div class="row">
                                <div class="col-md-4 form-group">
                                    <input type="text" name="Name" class="form-control" id="Name" placeholder="الاسم" required>
                                </div>
                                <div class="col-md-4 form-group mt-3 mt-md-0">
                                    <input type="number" class="form-control" name="PhoneNo" id="PhoneNo" placeholder="الهاتف" required>
                                </div>
                                <div class="col-md-4 form-group mt-3 mt-md-0">
                                    <input type="email" class="form-control" name="Email" id="Email" placeholder="البريد الاكتروني" required>
                                </div>
                            </div>
                            <div class="form-group mt-3">
                                <input type="text" class="form-control" name="Subject" id="Subject" placeholder="العنوان" required>
                            </div>
                            <div class="form-group mt-3">
                                <textarea class="form-control" name="message" rows="5" placeholder="الرسالة" required></textarea>
                            </div>

                            <div class="text-center"><button type="submit" onclick="Contact()">ارسال</button></div>
                        </form>

                    </div>

                </div>

            </div>
        </section><!-- End Contact Section -->

    </main><!-- End #main -->
    <!-- ======= Footer ======= -->
    <footer id="footer">

        <div class="footer-top">
            <div class="container">
                <div class="row">

                    <div class="col-lg-4 col-md-6 footer-contact">
                        <h3>شركة النماء لتقنية المعلومات</h3>
                        <p>
                            ليبيا<br>
                            طرابلس<br>
                            حي دمشق <br><br>
                            <strong>الهاتف:</strong> 218 91888365+<br>
                            <strong>البريد الاكتروني:</strong> info@tamam.ly<br>
                        </p>
                    </div>

                    <div class="col-lg-3 col-md-6 footer-links">
                        <h4>روابط سريعة</h4>
                        <ul>
                            <li><i class="bx bx-chevron-right"></i> <a href="#">الرئيسية</a></li>
                            <li><i class="bx bx-chevron-right"></i> <a href="#">عن الشركة</a></li>
                            <li><i class="bx bx-chevron-right"></i> <a href="#">خدماتنا</a></li>


                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-6 footer-links">
                        <h4>خدماتنا</h4>
                        <ul>
                            <li><i class="bx bx-chevron-right"></i> <a href="#">برمجة النظام الصحي</a></li>
                            <li><i class="bx bx-chevron-right"></i> <a href="#">دعم تقني 24 / 7</a></li>
                            <li><i class="bx bx-chevron-right"></i> <a href="#">منظومات تجارية و صحية</a></li>

                        </ul>
                    </div>
                    <div class="col-lg-2 col-md-6 footer-links">
                      <h4 style="text-align: center;">تطبيقاتنا</h4>
                      <ul>

                          <li>
                            <a href="https://play.google.com/store/apps/details?id=com.namacomp.tamam_app" target="_blank">
                              <img src="/assets/Images/logo/google-play-badge.png"/>
                            </a>"
                           </li>
                          <li><img src="/assets/Images/logo/download-on-the-app-store.png"/></li>

                      </ul>
                  </div>

                </div>
            </div>
        </div>

        <div class="container d-md-flex py-4">

            <div class="me-md-auto text-center text-md-start">
                <div class="copyright">
                    &copy; <strong><span>شركة النماء لتقنية المعلومات</span></strong>{{currentyear}}-2000
                </div>
                <div class="credits">
                    <!-- All the links in the footer should remain intact. -->
                    <!-- You can delete the links only if you purchased the pro version. -->
                    <!-- Licensing information: https://bootstrapmade.com/license/ -->
                    <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/medilab-free-medical-bootstrap-theme/ -->
                    تم التصميم بواسطة<a href="https://tamam.ly/">شركةالنماء لتقنية المعلومات</a>
                </div>
            </div>
            <div class="social-links text-center text-md-right pt-3 pt-md-0">
                <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>

                <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                <a href="https://play.google.com/store/apps/details?id=com.namacomp.tamam_app" target="_blank" class="linkedin"><i class="bi bi-google-play"></i></a>

            </div>
        </div>
    </footer><!-- End Footer -->


    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

    <script src="~/lib/jquery/dist/jquery.min.js"></script>
    <script src="~/lib/jquery-validation/dist/jquery.validate.min.js"></script>
    <script src="~/lib/jquery-validation-unobtrusive/jquery.validate.unobtrusive.min.js"></script>
    <script src="~/js/noty.min.js"></script>
    <script src="~/js/General.js"></script>
    <!-- Vendor JS Files -->
    <script src="~/home_assets/vendor/purecounter/purecounter_vanilla.js"></script>
    <script src="~/home_assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
    <script src="~/home_assets/vendor/glightbox/js/glightbox.min.js"></script>
    <script src="~/home_assets/vendor/swiper/swiper-bundle.min.js"></script>
    <script src="~/home_assets/vendor/php-email-form/validate.js"></script>

    <!-- Template Main JS File -->
    <script src="~/home_assets/js/main.js"></script>
    <script>


        function Contact() {


            var form = $('form#contactForm');
            if (form.valid()) {
                $.ajax({
                    url: '/SendContactUs',
                    type: 'POST',
                    data: form.serialize(),
                    success: function (result) {
                        notificationNoty("العملية صيحية", 'success');
                        $('#contactForm')[0].reset()
                    },
                    error: function () {

                    }
                });
            }

        }
    </script>
</body>

</html>
<router-outlet></router-outlet>
