import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericCrudService<T> {
  url = '';
  baseURL = '';


  constructor(private httpClient: HttpClient) {
    this.baseURL = environment.apiUrl;
  }

  setSegmantName(segmantName: string): GenericCrudService<T> {
    this.url = this.baseURL + segmantName + '/';
    return this;
  }
  setControllerAction(controllerName: string,ActionName): GenericCrudService<T> {
    this.url = this.baseURL + controllerName+'/'+ActionName ;
    return this;
  }
  getById(id: number): Observable<T> {
    let params = new HttpParams().set('id', id.toString());
    return this.httpClient.get<T>(this.url + 'GetById', { params: params });
  }

  getList(Path:String): Observable<T[]> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('jwt') })
    };
    return this.httpClient.get<T[]>(this.url + Path,httpOptions);
  }
  getListWithParam(Path:String,params:HttpParams): Observable<T[]> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('jwt') }),
      params:params
    };
    return this.httpClient.get<T[]>(this.url + Path, httpOptions);
  }

  insertItem(item: T, refresh: Function) {
    return this.httpClient.post<T>(this.url + 'Create', item).subscribe((data) => refresh(), (error) => console.log(error));
  }

  insertForm(item: any, refresh: Function) {
    const formData = new FormData();
    Object.keys(item).forEach(key => { formData.set(key, item[key]); });
    return this.httpClient.post<T>(this.url + 'Create', formData).subscribe(
      (data) => refresh(),
      (error) => console.log(error)
    );
  }

  updateItem(item: T,endPointName: string) {

    return this.httpClient.put<T>(this.url + endPointName, item)
  }

  updateForm(item: any, refresh: Function) {
    debugger
    const formData = new FormData();
    Object.keys(item).forEach(key => { formData.set(key, item[key]); });
    return this.httpClient.put<T>(this.url + 'Update', formData).subscribe(
      (data) => refresh(),
      (error) => console.log(error)
    );
  }

  deleteById(id: number, refresh: Function) {
    let params = new HttpParams().set('id', id.toString());
    console.log(this.url + 'Delete' + '?id=' + id.toString());
    this.httpClient.delete(this.url + 'Delete' + '?id=' + id.toString()).subscribe((data) => refresh(), (error) => console.log(error));
    console.log("dletete done");
  }

  Post(item: T) {
    const formData = new FormData();
    Object.keys(item).forEach(key => {
      if( item[key] && item[key]!="" )
      {

      }
      else{
        item[key]=null
      }

    });
    const body=JSON.stringify(item);
    
    const headers = { 'content-type': 'application/json'}
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('jwt') })
    };

   // return this.httpClient.post<any>(this.baseURL + 'Login/', body,{'headers':headers})
    return this.httpClient.post<T>(this.url , body,httpOptions)
}
}
