<div class="tamam-user-menu">
<!--<a (click)="close()"
     [routerLink]="['/apps/social']"
     class="tamam-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="tamam-user-menu-item__icon icon-sm" svgIcon="mat:account_circle"></mat-icon>
    <div class="tamam-user-menu-item__label">Your Profile</div>
  </a>

  <a (click)="close()"
     [routerLink]="['/']"
     class="tamam-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="tamam-user-menu-item__icon icon-sm" svgIcon="mat:insights"></mat-icon>
    <div class="tamam-user-menu-item__label">Analytics</div>
    <div class="tamam-user-menu-item__badge">NEW</div>
  </a>

  <a (click)="close()"
     [routerLink]="['/apps/social/timeline']"
     class="tamam-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="tamam-user-menu-item__icon icon-sm" svgIcon="mat:settings"></mat-icon>
    <div class="tamam-user-menu-item__label">Account Settings</div>
  </a>

  <div class="border-b border-divider mx-4"></div>

  <div class="tamam-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="tamam-user-menu-item__icon icon-sm" svgIcon="mat:switch_account"></mat-icon>
    <div class="tamam-user-menu-item__label">Switch Account</div>
    <mat-icon class="tamam-user-menu-item__icon icon-sm" svgIcon="mat:chevron_right"></mat-icon>
  </div>
-->
  <a (click)="close()"
     [routerLink]="['/clinic']"
     class="tamam-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="tamam-user-menu-item__icon icon-sm" svgIcon="mat:logout"></mat-icon>
    <div class="tamam-user-menu-item__label">تسجيل خروج</div>
  </a>
  <a  (click)="openPaymentDialog()"
  type="button"
     class="tamam-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="tamam-user-menu-item__icon icon-sm" svgIcon="mat:done_all"></mat-icon>
    <div class="tamam-user-menu-item__label">انشاء كود التفعيل</div>
  </a>
</div>
