import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ClientRequest } from 'http';
import { ActivationCodesDTO, CodesDTO } from '../../interfaces/ActivationCodesDTO';
import { PaymentService } from '../../services/payment.service';
import { HelperService } from '../../services/shared/helper.service';
import {Clipboard} from '@angular/cdk/clipboard';
@Component({
  selector: 'tamam-activation-codes',
  templateUrl: './activation-codes.component.html',
  styleUrls: ['./activation-codes.component.scss']
})
export class ActivationCodesComponent implements OnInit {
 Activation :CodesDTO[]
 displayedColumns: string[] = [ 'Code', 'ReferenceStr', 'CreationDate'];
  constructor(private  paymentService:PaymentService, public helperService: HelperService, private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.paymentService.getActivationCodes().subscribe(
      data =>{

       this.Activation=data
     });
  }
  FromatDate(date :string)
  {
    return this.helperService.FromatDate(date)
  }

  CopyCode(item){

    this.clipboard.copy(item);
    this.helperService.openSnackBar("تم النسخ")
  }
}
