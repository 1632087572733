<div class="footer flex">
  <div class="flex-auto flex items-center container">

    <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block">
      تطوير شركة النماء لتقنية المعلومات
      {{selectedYear}} &copy;

    </div>
  </div>
</div>
